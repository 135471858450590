
import { Card, CardContent, CardHeader } from "@/Components/ui/card";
import { Progress } from "@/Components/ui/progress";
import { cn } from "@/lib/utils";
import step2 from '@assets/choosing-clothes-animate.svg';
import step3 from '@assets/delivery-address-animate.svg';
import step1 from '@assets/website-creator-animate.svg';
import { Icon } from "@iconify/react";
import { useCallback, useMemo } from "react";

export default function ThreeSteps() {
    return <Card className="w-full shadow-xl min-h-[60vh] rounded-2xl  mt-8 mx-auto bg-[#f6f5f5]">
        <CardHeader className="flex flex-col justify-center items-center lg:gap-y-6 gap-y-4 py-8 w-full">
            <h1 className="font-bold text-4xl">How It Works</h1>
            <h3 className="font-semibold text-xl">Step By Step</h3>
            <StepByStep className="lg:max-w-[900px] max-w-[500px]" activeStep={3} />
        </CardHeader>
        <CardContent className="w-full flex flex-col lg:pb-12 pb-6 justify-center items-center">
            <div className="flex md:flex-row flex-col max-w-7xl w-full justify-start items-start lg:gap-8 gap-4">
                <div data-aos="fade-up" className="md:w-1/3 w-full flex flex-col justify-center items-center ">
                    <div className="rounded-[30px] shadow-xl shadow-spread-2 flex flex-col justify-center items-center aspect-square bg-white size-[300px]">
                        <img src={step1} className="rounded-2xl" />
                    </div>
                    <p className="max-w-[300px] text-center font-semibold text-gray-600 p-4">Create your own design, or select and customize one of the stunning designs from our collection.</p>
                </div>
                <div data-aos="fade-up" className="md:w-1/3 w-full flex flex-col justify-center items-center ">
                    <div className="rounded-[30px] shadow-xl shadow-spread-2 flex flex-col justify-center items-center aspect-square bg-white size-[300px]">
                        <img src={step2} className="rounded-2xl" />
                    </div>
                    <p className="max-w-[300px] text-center font-semibold text-gray-600 p-4">Personalize one of our available products, including t-shirts, sweaters, mugs, and more, with your unique design.</p>
                </div>
                <div data-aos="fade-up" className="md:w-1/3 w-full flex flex-col justify-center items-center ">
                    <div className="rounded-[30px] shadow-xl shadow-spread-2 flex flex-col justify-center items-center aspect-square bg-white size-[300px]">
                        <img src={step3} className="rounded-2xl" />
                    </div>
                    <p className="max-w-[300px] text-center font-semibold text-gray-600 p-4">Place your order and sit back while your custom product makes its way to you.</p>
                </div>
            </div>
        </CardContent>
    </Card>
}


export const StepByStep = ({ activeStep = 1.5, className = "", dense = false, hideLabelOnMobile = true }) => {
    const progress = useMemo(() => {
        if (activeStep <= 1) return 0;
        if (activeStep === 1.5) return 25;
        if (activeStep === 1.75) return 40;
        if (activeStep === 2) return 50;
        if (activeStep === 2.5) return 75;
        return 100;
    }, [activeStep]);

    const active = useCallback((stepNumber) => {
        return stepNumber <= activeStep;
    }, [activeStep]);

    return <div data-aos="fade-up" className={cn("flex text-primary flex-col relative rounded-2xl justify-start mt-4 items-start h-[75px] w-full", className)}>
        <div className="max-w-7xl mt-4 w-full">
            <Progress value={progress} className="h-1" activeBgClass="bg-cyan-600" inactiveBgClass="bg-gray-400" />
        </div>
        <div className={cn("max-w-7xl justify-center absolute w-full flex flex-row items-center flex w-full", dense ? "top-1.5" : 'top-0.9')}>
            <div className="relative w-full flex flex-row justify-between items-center">
                <div data-tooltip-id="rmp-tooltip" data-tooltip-html="Create/Choose Design" className={cn("relative text-center flex justify-center items-center border-2 rounded-full bg-white !aspect-square", dense ? "size-6" : "size-8", active(1) ? "bg-cyan-600" : "bg-white")}>
                    <Icon icon="tdesign:numbers-1-1" className={cn(active(1) ? "text-white" : "text-primary")} />
                    <span className={cn("font-semibold text-xs text-center whitespace-nowrap left-1/2  transform -translate-x-1/2 absolute", dense ? "top-8" : "top-10", hideLabelOnMobile ? "md:visible invisible" : "")}>Create/Choose Design</span>
                </div>
                <div data-tooltip-id="rmp-tooltip" data-tooltip-html="Add To Product" className={cn("relative text-center flex justify-center items-center border-2 rounded-full bg-white !aspect-square", dense ? "size-6" : "size-8", active(2) ? "bg-cyan-600" : "bg-white")}>
                    <Icon icon="tdesign:numbers-2-1" className={cn(active(2) ? "text-white" : "text-primary")} />
                    <span className={cn("font-semibold text-xs text-center whitespace-nowrap left-1/2  transform -translate-x-1/2 absolute", dense ? "top-8" : "top-10", hideLabelOnMobile ? "md:visible invisible" : "")}>Add To Product</span>
                </div>
                <div data-tooltip-id="rmp-tooltip" data-tooltip-html="Place Order" className={cn("relative text-center flex justify-center items-center border-2 rounded-full bg-white !aspect-square", dense ? "size-6" : "size-8", active(3) ? "bg-cyan-600" : "bg-white")}>
                    <Icon icon="tdesign:numbers-3-1" className={cn(active(3) ? "text-white" : "text-primary")} />
                    <span className={cn("font-semibold text-xs text-center whitespace-nowrap left-1/2  transform -translate-x-1/2 absolute", dense ? "top-8" : "top-10", hideLabelOnMobile ? "md:visible invisible" : "")}>Place Order</span>
                </div>
            </div>
        </div>
    </div>
}

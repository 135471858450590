import * as React from "react"
import * as ProgressPrimitive from "@radix-ui/react-progress"

import { cn } from "@/lib/utils"

const Progress = React.forwardRef(({ className, inactiveBgClass = "bg-secondary", activeBgClass = "bg-primary", value, ...props }, ref) => (
    <ProgressPrimitive.Root
        ref={ref}
        className={cn("relative h-4 w-full overflow-hidden rounded-full", inactiveBgClass, className)}
        {...props}>
        <ProgressPrimitive.Indicator
            className={cn("h-full w-full flex-1 transition-all", activeBgClass)}
            style={{ transform: `translateX(-${100 - (value || 0)}%)` }} />
    </ProgressPrimitive.Root>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
